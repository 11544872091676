// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-category-newsletter-js": () => import("./../../../src/pages/category/newsletter.js" /* webpackChunkName: "component---src-pages-category-newsletter-js" */),
  "component---src-pages-category-retreats-js": () => import("./../../../src/pages/category/retreats.js" /* webpackChunkName: "component---src-pages-category-retreats-js" */),
  "component---src-pages-contact-persons-js": () => import("./../../../src/pages/contact-persons.js" /* webpackChunkName: "component---src-pages-contact-persons-js" */),
  "component---src-pages-en-prayers-js": () => import("./../../../src/pages/en/prayers.js" /* webpackChunkName: "component---src-pages-en-prayers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-prayers-js": () => import("./../../../src/templates/prayers.js" /* webpackChunkName: "component---src-templates-prayers-js" */)
}

